@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'















































































































































































.title-item
  font-size: 1.2rem
  border-top: 1px solid $grey-4
  min-height: 4.0rem
